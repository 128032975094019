<template>
  <v-container fluid class="content-wrapper">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600 mb-0">{{ $t("SigninUserAndRole.title_user") }}</h4>
      <v-btn
        icon
        class="ml-3"
        @click="[(invoiceToActive = true), initAll()]"
        :class="loadingInviteUser && 'animate__spining'"
        :disabled="loadingInviteUser"
      >
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <div v-if="loadingInviteUser" class="content-body">
      <v-skeleton-loader type="article"></v-skeleton-loader>
    </div>
    <div v-else class="content-body">
      <div class="row match-height">
        <div class="col-12 pb-1">
          <div class="row">
            <div class="col-xl-3 col-md-4">
              <!-- <div class="form-group">
              <select class="form-control" id="">
                <option>Select Group</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
              </select>
            </div>-->
              <div
                class="dropdown select"
                @click="getfocus('select_new_role')"
                @mouseleave="mouseOver('select_new_role')"
              >
                <a id="select_new_role" style="z-index: 99999">
                  <v-select
                    v-model="select_filter_role"
                    :items="itemsFilterRole"
                    item-text="name"
                    item-value="value"
                    label
                    :placeholder="$t('SigninUserAndRole.header_role')"
                    :no-data-text="$t('SignupStep.nodata')"
                    color="#1e9ff2"
                    class="font-14px"
                    solo
                    flat
                    dense
                    single-line
                    hide-details
                    @change="selectFilterFunc()"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content
                        @mouseenter="getfocus('select_new_role')"
                        @mouseleave="mouseOver('select_new_role')"
                      >
                        <v-list-item-title
                          v-html="data.item.name"
                          class="title-signup-sj font-14px"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </a>
              </div>
            </div>
            <div class="col-xl-2 col-md-3 ml-auto">
              <a
                class="btn btn-pink btn-min-width btn-glow btn-block d-flex align-center justify-center"
                @click="inviteUserFunc"
                style="color: #fff !important"
                >{{ $t("SigninUserAndRole.title_invite_user") }}</a
              >
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="row">
            <div id="recent-transactions" class="col-12">
              <div class="card">
                <div class="card-content px-2 py-4">
                  <form class="form form-horizontal">
                    <div class="form-body">
                      <v-data-table
                        :headers="headers"
                        :items="inviteUserItems"
                        class="elevation-0"
                      >
                        <template v-slot:item.user_img="{ item }">
                          <!-- <div
                          class="
                            d-flex
                            flex-row
                            user-media
                            text-truncate text-center
                            align-center
                            justify-start
                            py-3
                          "
                        >
                        <div class="d-flex justify-start">-->
                          <img
                            :src="item.picture || require('@/assets/images/noAvatar.png')"
                            class="avatar avatar-lg mr-2"
                          />
                          <!-- <div
                              class="
                                d-flex
                                flex-column
                                align-start
                                justify-center
                              "
                              style="height: 2.25rem;"
                            >
                              <b>{{ item.firstname }} {{ item.lastname }}</b>
                              <span>{{ item.email }}</span>
                            </div>
                          </div>
                        </div>-->
                        </template>
                        <template v-slot:item.user_details="{ item, index }">
                          <div class="d-flex flex-column align-start justify-center">
                            <b v-if="item.fullname">{{
                              fullNameUserFunc(item, index)
                            }}</b>
                            <b v-else>{{ item.firstname }} {{ item.lastname }}</b>
                          </div>
                        </template>
                        <template v-slot:item.user_email="{ item }">
                          <div class="d-flex flex-column align-start justify-center">
                            <span>{{ item.email }}</span>
                          </div>
                        </template>
                        <template v-slot:item.user_status="{ item }">
                          <div class="d-flex flex-column align-center justify-center">
                            <v-chip
                              :color="
                                item.status == 'active'
                                  ? 'success'
                                  : item.status == 'pending'
                                  ? 'inactive'
                                  : 'error'
                              "
                              >{{
                                $t(`SigninUserAndRole.statususer_${item.status}`)
                              }}</v-chip
                            >
                          </div>
                        </template>
                        <template v-slot:item.user_role="{ item }">
                          <div class="d-flex flex-column align-center justify-start">
                            <b>
                              {{
                                item.role == "staff"
                                  ? $t("SigninUserAndRole.role_staff")
                                  : item.role == "provider"
                                  ? $t("SigninUserAndRole.role_admin")
                                  : item.role
                              }}
                            </b>
                          </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            v-if="item.role == 'staff' && item.refer_by == userData._id"
                            :disabled="!item.manage_action"
                            class="mr-3"
                            @click="delUserInvitedFunc(item)"
                            >mdi-trash-can-outline</v-icon
                          >
                          <v-icon
                            :disabled="!item.manage_action && item._id !== userData._id"
                            @click="editUserFunc(item)"
                            >mdi-pencil-outline</v-icon
                          >
                        </template>
                        <template v-slot:item.active="{ item }">
                          <v-switch
                            v-model="item.status_active"
                            inset
                            color="success"
                            @click="changeStatusUser(item)"
                            :disabled="
                              !item.manage_action ||
                              item.status == 'pending' ||
                              item.refer_by !== userData._id
                            "
                          ></v-switch>
                        </template>
                      </v-data-table>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-dialog v-model="dialogEditUser" max-width="45rem">
        <v-card class="py-3">
          <v-card-text>
            <div
              v-if="loadingDialogEditUser"
              class="d-flex align-center justify-center pa-5 pb-0"
            >
              <img
                :src="require('@/assets/img/thumb/loading.gif')"
                class="img-gif-loading"
              />
            </div>
            <v-row
              v-if="!dialogConfirmSave && !loadingDialogEditUser && itemEditUser"
              class="pa-5 pb-0"
            >
              <v-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-center justify-start"
              >
                <b>{{ $t("SigninUserAndRole.email") }}:</b>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="8"
                lg="8"
                class="d-flex align-center justify-start"
                style="white-space: break-spaces; overflow-wrap: anywhere"
              >
                <span>{{ itemEditUser.email }}</span>
              </v-col>

              <!-- <v-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-center justify-start"
              >
                <b>{{ $t("SigninInvoiceAndBank.fullname") }}:</b>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="8"
                lg="8"
                class="d-flex align-center justify-start"
              >
                <input
                  type="text"
                  id="editfullname"
                  class="form-control"
                  :placeholder="$t('SigninInvoiceAndBank.fullname')"
                  name="editfullname"
                  v-model="itemEditUser.fullname"
                  style="min-height: 3rem; border: 1px solid var(--border-gray)"
                />
              </v-col> -->
              <v-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-center justify-start"
              >
                <b>{{ $t("SigninInvoiceAndBank.label_firstname") }}:</b>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="8"
                lg="8"
                class="d-flex align-center justify-start"
              >
                <input
                  type="text"
                  id="editfirstname"
                  class="form-control"
                  :placeholder="$t('SigninInvoiceAndBank.label_firstname')"
                  name="editfirstname"
                  v-model="itemEditUser.firstname"
                  style="min-height: 3rem; border: 1px solid var(--border-gray)"
                />
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-center justify-start"
              >
                <b>{{ $t("SigninInvoiceAndBank.label_lastname") }}:</b>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="8"
                lg="8"
                class="d-flex align-center justify-start"
              >
                <input
                  type="text"
                  id="editlastname"
                  class="form-control"
                  :placeholder="$t('SigninInvoiceAndBank.label_lastname')"
                  name="editlastname"
                  v-model="itemEditUser.lastname"
                  style="min-height: 3rem; border: 1px solid var(--border-gray)"
                />
              </v-col>

              <!-- <v-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-center justify-start"
              >
                <b>{{ $t("SigninInvoiceAndBank.label_lastname") }}:</b>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="8"
                lg="8"
                class="d-flex align-center justify-start"
              >
                <input
                  type="text"
                  id="editfullname"
                  class="form-control"
                  :placeholder="$t('SigninInvoiceAndBank.label_lastname')"
                  name="editfullname"
                  v-model="itemEditUser.lastname"
                  style="min-height: 3rem; border: 1px solid var(--border-gray)"
                />
              </v-col> -->

              <v-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-center justify-start"
              >
                <b>{{ $t("SigninUserAndRole.role") }}:</b>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="8"
                lg="8"
                class="d-flex align-center justify-start"
              >
                <span>
                  {{
                    itemEditUser.role == "staff"
                      ? $t("SigninUserAndRole.role_staff")
                      : itemEditUser.role == "provider"
                      ? $t("SigninUserAndRole.role_admin")
                      : itemEditUser.role
                  }}
                </span>
              </v-col>

              <!-- <v-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                class="d-flex align-center justify-start"
                v-if="itemEditUser.role == 'staff'"
              >
                <b>{{ $t("SigninUserAndRole.role_new") }}:</b>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="8"
                lg="8"
                class="d-flex align-center justify-start"
                v-if="itemEditUser.role == 'staff'"
              >
                <div
                  class="dropdown select"
                  @click="getfocus('select_new_role')"
                  @mouseleave="mouseOver('select_new_role')"
                  style="width: 100%"
                >
                  <a id="select_new_role" style="z-index: 99999">
                    <v-select
                      v-model="select_new_role"
                      :items="itemsRole"
                      item-text="name"
                      item-value="value"
                      label
                      :placeholder="$t('SignupStep.select_one')"
                      :no-data-text="$t('SignupStep.nodata')"
                      color="#1e9ff2"
                      class="font-14px"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                    >
                      <template v-slot:item="data">
                        <v-list-item-content
                          @mouseenter="getfocus('select_new_role')"
                          @mouseleave="mouseOver('select_new_role')"
                        >
                          <v-list-item-title
                            v-html="data.item.name"
                            class="title-signup-sj font-14px"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </a>
                </div>
              </v-col> -->
            </v-row>
            <div
              v-if="dialogConfirmSave && !loadingDialogEditUser"
              class="d-flex align-center justify-center pa-5 pb-0"
            >
              <b class="title-signup-sj">
                {{
                  `${$t("SigninUserAndRole.confirm_save_user_data")} ${
                    itemEditUser.email
                  } ${$t("SigninUserAndRole.confirm_save_role3")}`
                }}
              </b>
            </div>
          </v-card-text>

          <v-card-actions v-if="!dialogConfirmSave && !loadingDialogEditUser">
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="closeUserFunc">
              {{ $t("SigninUserAndRole.btn_cancel") }}
            </v-btn>
            <v-btn
              color="pink"
              style="color: #fff !important"
              depressed
              dark
              elevation="0"
              @click="confirmSaveUserFunc"
              >{{ $t("SigninUserAndRole.btn_save") }}</v-btn
            >
          </v-card-actions>

          <v-card-actions v-if="dialogConfirmSave && !loadingDialogEditUser">
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="closeUserFunc">
              {{ $t("SigninUserAndRole.btn_cancel") }}
            </v-btn>
            <v-btn
              color="pink"
              style="color: #fff !important"
              depressed
              dark
              elevation="0"
              @click="saveUserFunc"
              >{{ $t("SigninUserAndRole.btn_save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "SigninUserScreen",
  metaInfo: {
    title: "User",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    loadingInviteUser: true,
    inviteUserItems: [],
    inviteOldUserItems: [],
    dialogEditUser: false,
    loadingDialogEditUser: false,
    itemEditUser: null,
    itemsDefaultRole: [],
    itemsRole: [],
    select_new_role: null,
    dialogConfirmSave: false,

    headers: [],
    value_element: "",
    select_filter_role: null,
    userData: null,
  }),
  computed: {},
  watch: {},
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;

    self.initAll();
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("focus-2");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.add("focus-2");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("focus-2");
        element.classList.remove("focus-2");
        this.value_element = "";
      }
    },
    createUserFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const link = {
        name: "SigninCreateUserScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      EventBus.$emit("changePathname", link);
      EventBus.$emit("endloading");
    },
    inviteUserFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      const link = {
        name: "SigninInviteUserScreen",
        params: {
          propertyId: self.propertyID,
          userToken: self.userToken,
        },
      };
      EventBus.$emit("changePathname", link);
      EventBus.$emit("endloading");
    },
    initAll() {
      const self = this;

      self.headers = [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "user_img",
        },
        {
          text: self.$t("SigninInvoiceAndBank.fullname"),
          align: "center",
          sortable: false,
          value: "user_details",
        },
        {
          text: self.$t("SigninInvoiceAndBank.email"),
          align: "center",
          sortable: false,
          value: "user_email",
        },
        {
          text: self.$t("PropertyManagement.header_status"),
          align: "center",
          sortable: false,
          value: "user_status",
        },
        {
          text: self.$t("SigninUserAndRole.header_role"),
          align: "center",
          sortable: false,
          value: "user_role",
        },
        {
          text: self.$t("SigninUserAndRole.header_action"),
          align: "center",
          sortable: false,
          value: "actions",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "active",
        },
      ];

      self.itemsDefaultRole = [
        {
          value: "provider",
          name: self.$t("SigninUserAndRole.role_admin"),
        },
        {
          value: "staff",
          name: self.$t("SigninUserAndRole.role_staff"),
        },
      ];
      self.itemsFilterRole = [
        {
          value: null,
          name: self.$t("SigninUserAndRole.header_all"),
        },
        {
          value: "provider",
          name: self.$t("SigninUserAndRole.role_admin"),
        },
        {
          value: "staff",
          name: self.$t("SigninUserAndRole.role_staff"),
        },
      ];
      self.initUserData();
    },
    async initUserData() {
      const self = this;
      var temp = null;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/my_user", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          temp = res?.data?.result || null;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || "Please try again",
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.userData = temp;
        self.initUserInviteFunc();
      }
    },
    async initUserInviteFunc() {
      const self = this;
      self.loadingInviteUser = true;
      self.inviteUserItems = [];
      var tmpOldUser = [];
      var tmpUser = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/staff/?property=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log(res);

          res?.data?.result?.map((el) => {
            if (el?.email)
              tmpOldUser.push({
                ...el,
                fullname: el?.name || `${el?.firstname} ${el?.lastname}` || "",
                status_active: el?.status == "active" ? true : false,
                manage_action:
                  (self.userData?.role == "provider" || self.userData?.role == "admin") &&
                  el?.refer_by &&
                  el?.refer_by == self.userData?._id
                    ? true
                    : false,
                role: el?.role == "admin" ? "provider" : el?.role,
              });
            tmpUser.push({
              ...el,
              fullname: el?.name || `${el?.firstname} ${el?.lastname}` || "",
              status_active: el?.status == "active" ? true : false,
              manage_action:
                (self.userData?.role == "provider" || self.userData?.role == "admin") &&
                el?.refer_by &&
                el?.refer_by == self.userData?._id
                  ? true
                  : false,
              role: el?.role == "admin" ? "provider" : el?.role,
            });
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.inviteOldUserItems = tmpOldUser;
        self.inviteUserItems = tmpUser;
        // console.log(tmpUser);
        self.loadingInviteUser = false;
        EventBus.$emit("endloading");
      }
    },
    async changeStatusUser(item) {
      const self = this;
      self.loadingInviteUser = true;
      var obj = {
        status: item.status_active ? "active" : "inactive",
        _id: item._id,
      };
      if (item?.refer_by) obj.refer_by = item?.refer_by;

      // console.log("obj: ", obj);
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API + `/staff/user?staff_id=${item._id}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        // if (res.status == 200) {
        //   swal(
        //     self.$t("Alert.success_title"),
        //     self.$t("Alert.save_success"),
        //     self.$t("Alert.success_label"),
        //     {
        //       button: false,
        //       timer: 2000,
        //     }
        //   );
        // }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        // self.dialogEditUser = false;
        // self.loadingDialogEditUser = false;
        // self.dialogConfirmSave = false;
        self.initAll();
      }
    },
    editUserFunc(item) {
      const self = this;
      var tmpFullname = item?.fullname || "";
      self.itemUserDetail = item;
      self.itemEditUser = item;
      self.itemEditUser.fullname = tmpFullname;
      self.dialogEditUser = true;
      self.loadingDialogEditUser = true;
      // console.log(item);
      var tmpRole = [];
      self.itemsDefaultRole?.map((p) => {
        if (p.value !== item.role) tmpRole.push(p);
      });
      self.itemsRole = tmpRole;

      setTimeout(() => {
        self.loadingDialogEditUser = false;
      }, 500);
    },
    closeUserFunc() {
      const self = this;
      self.dialogEditUser = false;
      self.loadingDialogEditUser = false;
      self.itemEditUser = null;
      self.select_new_role = null;
      self.dialogConfirmSave = false;
    },
    confirmSaveUserFunc() {
      const self = this;
      if (self.itemEditUser.fullname || self.select_new_role) {
        self.loadingDialogEditUser = true;
        self.dialogConfirmSave = true;
        // self.saveUserFunc()
        setTimeout(() => {
          self.loadingDialogEditUser = false;
        }, 500);
      } else {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("SigninUserAndRole.please_select_role"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    async saveUserFunc() {
      const self = this;
      self.loadingInviteUser = true;
      var obj = {
        role: self.select_new_role || self.itemEditUser.role,
        // name: self.itemEditUser.fullname,
        status: self.itemEditUser.status,
        // email: self.itemEditUser.email,
        firstname: self.itemEditUser.firstname,
        // invite: self.itemEditUser.invite,
        lastname: self.itemEditUser.lastname,
        // picture: self.itemEditUser.picture,
        // refer_by: self.itemEditUser.refer_by,
        _id: self.itemEditUser._id,
      };
      // if (self.itemEditUser.role !== "staff") obj._id = self.itemEditUser._id;
      if (self.itemEditUser.role == "staff")
        obj.refer_by = self.itemEditUser.refer_by || self.userData?._id;

      // console.log("obj: ", obj, self.itemEditUser);
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API + `/staff/user?staff_id=${self.itemEditUser._id}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          swal(
            self.$t("Alert.success_title"),
            self.$t("Alert.save_success"),
            self.$t("Alert.success_label"),
            {
              button: false,
              timer: 2000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } finally {
        self.dialogEditUser = false;
        self.loadingDialogEditUser = false;
        self.dialogConfirmSave = false;
        self.initAll();
      }
    },
    selectFilterFunc() {
      const self = this;
      self.initAll();
    },
    delUserInvitedFunc(item) {
      const self = this;
      // console.log("item: ", item);
      swal(
        `${self.$t("Alert.qtneedtoremove")} ${item?.email} ${self.$t("Alert.qtyesorno")}`,
        {
          dangerMode: true,
          buttons: {
            cancel: self.$t("Alert.btn_no"),
            confirm: {
              text: self.$t("Alert.btn_yes"),
              value: "confirm",
            },
          },
        }
      ).then(async (value) => {
        if (value === "confirm") {
          EventBus.$emit("loadingtillend");
          try {
            const res = await self.axios.delete(
              process.env.VUE_APP_API +
                `/staff?staff_id=${item._id}&refer_by=${
                  item?.refer_by || self.userData?._id
                }`,
              {
                headers: {
                  Authorization:
                    self?.userToken ||
                    localStorage?.getItem("Token") ||
                    self?.$store?.state?.resSignUpData?.access_token,
                  "X-Language-Code":
                    localStorage?.getItem("language") || self?.$store?.state?.language,
                },
              }
            );
          } catch (error) {
            EventBus.$emit("endloading");
            console.log(error?.response?.data?.message || error);
            swal(
              self.$t("Alert.warn_title"),
              error?.response?.data?.message || self.$t("Alert.tryagain"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          } finally {
            self.initAll();
          }
        }
      });
    },
    fullNameUserFunc(item, index) {
      return this.inviteOldUserItems[index].fullname || "";
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";

.avatar-lg {
  width: 3.375rem;
  height: 3.375rem;
}

.avatar {
  position: relative;
  width: 2.25rem;
  height: 2.25rem;
  white-space: nowrap;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  display: inline-flex;
  font-size: 0.8rem;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";
@import "../../../../styles/bootstrap/bootstrap.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}

.btn-pink {
  border-color: var(--pink) !important;
  background-color: var(--pink) !important;
  color: #ffffff;
}
</style>
